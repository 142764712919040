module.exports = [{
      plugin: require('/codebuild/output/src512817734/src/oneclickretargetinglp/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["PT Serif"]},"custom":{"families":["Inter"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('/codebuild/output/src512817734/src/oneclickretargetinglp/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NQ5BZVF","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('/codebuild/output/src512817734/src/oneclickretargetinglp/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"566277677254854"},
    },{
      plugin: require('/codebuild/output/src512817734/src/oneclickretargetinglp/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
